export const jiraStatusMixin = {
  methods: {
    jiraStatus_statusToObject (status) {
      if (status == null) {
        return {
          status: 'unavailable',
          color: 'black',
          buttonClass: 'secondary'
        }
      }
      switch(status.toLowerCase()) {
        case 'in preparation':
          return {
            status: status,
            color: 'blue',
            buttonClass: 'primary'
          }
        case 'prepared':
          return {
            status: status,
            color: 'green',
            buttonClass: 'primary'
          }
        case 'in operation':
          return {
            status: status,
            color: 'green',
            buttonClass: 'success'
          }
        case 'in operation (limited)':
          return {
            status: status,
            color: 'orange',
            buttonClass: 'warning'
          }
        case 'open':
          return {
            status: status,
            color: 'blue',
            buttonClass: 'primary'
          }
        case 'maintenance':
          return {
            status: status,
            color: 'red',
            buttonClass: 'danger'
          }
        case 'blocked':
          return {
            status: status,
            color: 'red',
            buttonClass: 'danger'
          }
        case 'end of season':
          return {
            status: status,
            color: 'gray',
            buttonClass: 'secondary'
          }
        case 'eol':
          return {
            status: status,
            color: 'gray',
            buttonClass: 'secondary'
          }
        default:
          return {
            status: 'unknown',
            color: 'orange',
            buttonClass: 'secondary'
          }
      }
    }
  }
}