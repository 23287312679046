<template>
  <!--
    The router-view (parent-view) of the installation-views.
    Used to add tabs around all installation-components.
  -->
  <Portlet
    v-if="installationObject"
    :title="portletTitle"
    :icon="jiraStatusAvailable ? portletIcon : 'hdd'"
    :environment-text="installationObject.environment"
    :show-environment-banner="!(authenticationHasRole('scope_customer') && authenticationHasRole('zerolevel')) && installationObject != null"
    :portlet-head-class="'m-portlet__head installation-portlet-head'"
    class="installationDetails"
  >
    <template
      slot="buttons"
    >
      <h5
        v-if="agreements.length > 0"
        class="font-weight-bold mb-0 mr-2"
      >
        SLA:
      </h5>
      <font-awesome-icon
        v-for="(agreement, index) in agreements"
        :key="`agreement-${ index }`"
        v-tooltip="agreement.name"
        class="mr-2"
        size="2x"
        :style="{ color: agreement.color }"
        :icon="agreement.icon"
      />
      <div class="d-none d-sm-block">
        <JiraStatusButton
          :jira-status="jiraStatus_statusToObject(installationObject.jiraStatus)"
          class="d-inline"
        />
        <a
          v-if="installationObject.jiraStatus != 'Open' && installationObject.jiraStatus != 'EOL' && installationObject.jiraStatus != 'End of Season'"
          :class="['btn btn-sm d-inline mr-2', { 'btn-success' : installationObject.monitoringEnabled}, { 'btn-danger' : !installationObject.monitoringEnabled}]"
          style="color: white;"
          @click="(authenticationHasRole('scope_staff') && !monitoringLoading) ? openStateModal() : ''"
        >
          <font-awesome-icon
            v-if="monitoringLoading"
            :class="['alt-pointer color-primary', { 'fa-spin' : monitoringLoading}]"
            icon="sync-alt"
            style="vertical-align: middle; color: white;"
          />
          <span v-else>{{ installationObject.monitoringEnabled ? $t('monitoringMode') : $t('maintenanceMode') }}</span>
        </a>
        <a
          :href="jiraHref"
          class="btn btn-primary btn-sm d-inline"
          target="_blank"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="['fab', 'jira']"
          />{{ installationId }}
        </a>
        <a
          :href="confluenceHref"
          class="btn btn-primary btn-sm d-inline ml-2"
          target="_blank"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="['fab', 'confluence']"
          />Confluence
        </a>
      </div>
      <div class="d-sm-none">
        <a
          :href="jiraHref"
          class="btn btn-primary btn-sm d-block"
          target="_blank"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="['fab', 'jira']"
          />{{ installationId }}
        </a>
        <JiraStatusButton
          v-if="!(authenticationHasRole('scope_customer') && authenticationHasRole('zerolevel'))"
          :jira-status="jiraStatus_statusToObject(installationObject.jiraStatus)"
          class="d-block"
        />
      </div>
    </template>
    <template
      v-if="installationObject"
      slot="afterHeadBorder"
    />

    <InstallationTabs
      v-if="installationObject && installationId"
      :installation-object="installationObject"
      :installation-id="installationId"
    />

    <router-view />
    <SweetModal
      ref="confirmModeChange"
      :title="installationObject.monitoringEnabled ? $t('maintenanceMode') : $t('monitoringMode')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ installationObject.monitoringEnabled ? $t('sureToSetMonitoringStateF') : $t('sureToSetMonitoringStateT') }}</p>
      <div class="form-group">
        <label class="float-left">{{ $t('comment') }} (optional)</label>
        <textarea
          v-model="comment"
          class="form-control"
          placeholder="please add a comment (optional)"
          type="text"
          rows="5"
        />
      </div>
      <div class="m-0 mb-3 mt-3" />
      <div
        class="form-check form-check-inline mb-3 mr-5"
      >
        <input
          id="executeAction"
          v-model="sendMsChat"
          class="form-check-input"
          type="checkbox"
        >
        <label
          class="form-check-label"
          for="executeAction"
        >Send a message to MS Teams chat</label>
      </div>
      <template v-if="hasMaintenanceSetting">
        <div
          v-if="installationObject.monitoringEnabled"
          class="form-check form-check-inline mb-3 mr-5"
        >
          <input
            id="activateMaintenanceMode"
            v-model="activateMaintenanceMode"
            class="form-check-input"
            type="checkbox"
          >
          <label
            class="form-check-label"
            for="activateMaintenanceMode"
          >Activate maintenance mode / page (Please do not use for deployments, use Automata instead!)</label>
        </div>
        <div
          v-if="!installationObject.monitoringEnabled"
          class="form-check form-check-inline mb-3 mr-5"
        >
          <input
            id="deactivateMaintenanceMode"
            v-model="deactivateMaintenanceMode"
            class="form-check-input"
            type="checkbox"
          >
          <label
            class="form-check-label"
            for="deactivateMaintenanceMode"
          >Deactivate maintenance mode / page (Please do not use for deployments, use Automata instead!)</label>
        </div>
      </template>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmModeChange.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-primary float-right mb-3"
        :disabled="clicked"
        @click="changeMonitoringState(!installationObject.monitoringEnabled)"
      >
        {{ 'Change' }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </Portlet>
</template>

<script>
import { jiraStatusMixin } from '@/mixins/jiraStatusMixin';
import { urlParseMixin } from '@/mixins/urlParseMixin';
import { mapGetters } from 'vuex';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: 'InstallationDetails',
  components: {
    InstallationTabs: () => import('@/components/Installation/InstallationTabs.vue'),
    JiraStatusButton: () => import('@/components/Jira/JiraStatusButton.vue'),
    SweetModal
  },
  mixins: [
    urlParseMixin,
    jiraStatusMixin,
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      installationObject: null,
      agreements: [],
      prevRoute: null,
      toRoute: null,
      comment: null,
      sendMsChat: true,
      deactivateMaintenanceMode: false,
      activateMaintenanceMode: false,
      monitoringLoading: false,
      clicked: false,
      hasMaintenanceSetting: false
    }
  },
  metaInfo () {
    return {
      title: `Installation Details ${ this.installationId }`
    }
  },
  computed: {
    ...mapGetters(['store_getJiraUrl', 'store_getConfluenceUrl']),
    jiraStatusAvailable () {
      if (this.installationObject == null) {
        return false;
      }
      if (this.installationObject.jiraStatus == null) {
        return false;
      }
      return true;
    },
    portletIcon () {
      let status = this.installationObject.jiraStatus.toLowerCase();

      if (status.includes('in operation (limited)')) {
        return 'band-aid';
      }
      if (status.includes('blocked')) {
        return 'bug';
      }
      if (status.includes('in operation')) {
        return 'cog';
      }
      if (status.includes('end-of-season') || status.includes('eos') || status.includes('end of season')) {
        return 'couch';
      }
      if (status.includes('end-of-life') || status.includes('eol') || status.includes('end of life')) {
        return 'couch';
      }
      if (status.includes('maintenance') || status.includes('ooo') || status.includes('out of order')) {
        return 'couch';
      }
      if (status.includes('in preparation')) {
        return 'hiking';
      }
      if (status.includes('prepared')) {
        return 'flag-checkered';
      }
      if (status.includes('open')) {
        return 'hourglass-half';
      }

      return 'hdd';
    },
    jiraHref: function () {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ this.installationId }`);
    },
    confluenceHref: function () {
      return this.urlParseMixin_combine(this.store_getConfluenceUrl, `label/${ this.installationId }`);
    },
    portletTitle () {
      if (this.installationObject) {
        return this.installationObject.installationType + ': ' + this.installationObject.name;
      }
      return this.$t('installationDetailsView.details');
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.prevRoute = from;
      vm.toRoute = to;
    })
  },
  created () {
    this.getInstallation();
    this.getSettingCheck();
  },
  methods: {
    openStateModal () {
      this.clicked = false;
      this.comment = null;
      this.sendMsChat = true;
      this.$refs.confirmModeChange.open();
    },
    changeMonitoringState (value) {
      this.clicked = true;
      this.$refs.confirmModeChange.close();
      this.monitoringLoading = true;

      let currentUser = this.$authenticator.getUser();
      let accessToken = currentUser.details.access_token;

      let body = {
        comment: this.comment,
        sendMsChat: this.sendMsChat,
        deactivateMaintenanceMode: this.deactivateMaintenanceMode,
        activateMaintenanceMode: this.activateMaintenanceMode,
        token: accessToken
      }

      this.axios.put(`/Installation/ChangeMonitoringState?id=${this.installationId}&enable=${value}`, body)
        .then((response) => {
          if(response && response.data) {
            this.installationObject.monitoringEnabled = value;
          }
        })
        .finally(() => {
          this.monitoringLoading = false;
          if (this.activateMaintenanceMode) {
           this.deactivateMaintenanceMode = true;
          }
          else {
            this.deactivateMaintenanceMode = false;
          }
          this.activateMaintenanceMode = false;
        })
    },
    getAgreements () {
      if(this.installationObject.slaAgreement) {
        var data = this.installationObject.slaAgreement;
        this.agreements = [];
        if(data == "ExternalHosted-Bronze") {
          this.agreements.push({name: 'External', icon: 'server', color: 'red'});
          this.agreements.push({name: 'Bronze', icon: 'file-contract', color: '#cd7f32'});
        }
        else if(data == "ExternalHosted-Silver") {
          this.agreements.push({name: 'External', icon: 'server', color: 'red'});
          this.agreements.push({name: 'Silver', icon: 'file-contract', color: '#C0C0C0'});
        }
        else if(data == "ExternalHosted-Gold") {
          this.agreements.push({name: 'External', icon: 'server', color: 'red'});
          this.agreements.push({name: 'Gold', icon: 'file-contract', color: '#d4af37'});
        }
        else if(data == "Summer-and-Winter") {
          this.agreements.push({name: 'Summer', icon: 'sun', color: '#f9d71c'});
          this.agreements.push({name: 'Winter', icon: 'snowflake', color: '#C0F6FB'});
        }
        else if(data == "Summer") {
          this.agreements.push({name: 'Summer', icon: 'sun', color: '#f9d71c'});
        }
        else if(data == "Winter") {
          this.agreements.push({name: 'Winter', icon: 'snowflake', color: '#C0F6FB'});
        }
        else if(data == "Gold") {
          this.agreements.push({name: 'Gold', icon: 'file-contract', color: '#d4af37'});
        }
        else if(data == "Silver") {
          this.agreements.push({name: 'Silver', icon: 'file-contract', color: '#C0C0C0'});
        }
        else if(data == "Bronze") {
          this.agreements.push({name: 'Bronze', icon: 'file-contract', color: '#cd7f32'});
        }
      }
    },
    getSettingCheck () {
      this.axios.get(`/Installation/CheckIfMaintenanceSetting?installationId=${ this.installationId }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.hasMaintenanceSetting = response.data;
      })
    },
    getInstallation () {
      this.loading = true;
      this.axios.get('/Installation/Get?id=' + this.installationId)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.installationObject = response.data;
          if (!this.installationObject.monitoringEnabled) {
            this.deactivateMaintenanceMode = this.installationObject.switchToMaintenanceMode
          }
          
          this.getAgreements();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
.m-portlet__head-tools img {
  height: 1rem;
  width: auto;
}
</style>
